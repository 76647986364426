const QuickLRU         = require('@alloc/quick-lru');
const h                = require('./wrapper');
const _                = require('lodash');
const ExpressionParser = require('./expression-parser').Parser;
const errors           = require('./errors');
const u                = require('updeep');
const { pathSplit: pathSplitInner, partsFromParsed, fakeDataProxyBase, fakeDataHandler, RANDOM_PATH_NUMBER } = require('./path-helpers');

const uRejectIndex = function(toReject) {
  return u.reject((v, i) => { return i === toReject; });
};

const CACHE_SIZE = (typeof(process) !== 'undefined' && Number(process.env.LSHB_CACHE_SIZE)) || 10000;

const renderCache = new QuickLRU({ maxSize: CACHE_SIZE });
const expCache = new QuickLRU({ maxSize: CACHE_SIZE });
const pathCache = new QuickLRU({ maxSize: CACHE_SIZE });

const pathSplit = (path) => {
  let parts = pathCache.get(path);
  if (parts === undefined) {
    parts = pathSplitInner(h, path);
    pathCache.set(path, parts);
  }
  return parts;
};

const lookupParts = function(parts, payload) {
  let index = 0;
  let value = payload;
  let type = typeof(payload);
  while (index < parts.length) {
    // trying to get a subproperty of null, undefined, boolean, or number
    // thats not valid, return undefined
    if (value === null || (type !== 'object' && type !== 'string')) {
      return undefined;
    }

    value = value[parts[index++]];
    type = typeof(value);

    // this points at a pre existing function, not allowed
    if (type === 'function') { return undefined; }
  }

  return value;
};

const idToString = function() { return this.id; };
const emptyStringFunc = () => '';

const payloadHelper = {
  compile: h.compile,

  _buildTemplateRenderer: (template, noBlocks) => {
    try {
      const parsed = h.parse(template);
      if (!parsed || !parsed.body || parsed.body.length === 0) {
        return emptyStringFunc;
      }

      const parts = partsFromParsed(parsed);
      if (parts) {
        // single handlebars path expression,
        // do simple no-cast-to-string lookup
        return (payload) => lookupParts(parts, payload);
      }

      return h.compile(parsed, noBlocks);
    } catch (e) {
      return () => { throw errors.InvalidTemplate({ message: e.message }); };
    }
  },

  _buildExpressionEvaluator: (expression) => {
    try {
      const parsed = ExpressionParser.parse(expression);
      const vars = [];
      parsed.variables().forEach((varTemplate) => {
        const renderer = payloadHelper._buildTemplateRenderer(`{{${varTemplate}}}`, true);
        vars.push([varTemplate, renderer]);
      });
      return (payload, options) => {
        const varMap = {};
        vars.forEach(([varTemplate, renderer]) => {
          varMap[varTemplate] = renderer(payload, options);
        });
        return parsed.evaluate(varMap);
      };
    } catch (e) {
      return () => { throw e; };
    }
  },

  isValidPath: function(path, { returnError } = {}) {
    const result = pathSplit(path);
    if (result === false) {
      return returnError ? errors.InvalidPayloadPath({ path }) : false;
    } else {
      return returnError ? null : true;
    }
  },

  isValidTemplate: function(template, { returnError } = {}) {
    try {
      payloadHelper.render(template, {});
      return returnError ? null : true;
    } catch (e) {
      return returnError ? e : false;
    }
  },

  isValidJsonTemplate: function(template, { returnError } = {}) {
    if (!template) { return true; }
    template = String(template);

    let proxy;
    if (typeof(Proxy) === 'function') {
      proxy = new Proxy(fakeDataProxyBase, fakeDataHandler);
    }

    try {
      if (!template.includes('{{')) {
        // doesn't actually have handlebars
        // so should be straight valid json
        JSON.parse(template);
      } else {
        let rendered = payloadHelper.renderToString(template, proxy || {});
        if (proxy) {
          try {
            JSON.parse(rendered);
          } catch {
            // attempt to quote unquoted RANDOM_PATH_NUMBER object keys
            // this is not perfect but it captures a lot of cases
            // see test case for example where it is broken in a super edge case
            rendered = rendered.replace(/\s/g, '').replace(new RegExp(`([\\,\\{])${RANDOM_PATH_NUMBER}:`, 'g'), `$1"${RANDOM_PATH_NUMBER}":`);
            JSON.parse(rendered);
          }
        }
      }
      return returnError ? null : true;
    } catch (e) {
      return returnError ? e : false;
    }
  },

  render: function(template, payload, { noErrors, castAsString, frameData } = {}) {
    if (!_.isString(template)) { return castAsString ? _.toString(template) : template; }
    if (!_.includes(template, '{{')) { return template; }

    let templateFunc = renderCache.get(template);
    if (!templateFunc) {
      templateFunc = payloadHelper._buildTemplateRenderer(template);
      renderCache.set(template, templateFunc);
    }

    let result;
    try {
      const options = frameData ? { data: frameData } : { };
      result = templateFunc(payload, options);
    } catch (e) {
      if (!noErrors) {
        if (e.type === 'InvalidTemplate') { throw e; }
        throw errors.InvalidTemplate({ message: e.message });
      }
      result = template;
    }

    return castAsString ? _.toString(result) : result;
  },

  renderToString: function(template, payload) {
    return payloadHelper.render(template, payload, { castAsString: true });
  },

  renderIfValid: function(template, payload) {
    return payloadHelper.render(template, payload, { noErrors: true });
  },

  renderIfValidToString: function(template, payload) {
    return payloadHelper.render(template, payload, { noErrors: true, castAsString: true });
  },

  lookup: function(path, payload) {
    const parts = pathSplit(path);
    if (parts === false) {
      throw errors.InvalidPayloadPath({ path });
    }
    if (!parts) { return undefined; }

    return lookupParts(parts, payload);
  },

  set: function(path, value, payload) {
    if (value === undefined) {
      return payloadHelper.remove(path, payload);
    }

    const parts = pathSplit(path);
    if (parts === false) { throw errors.InvalidPayloadPath({ path }); }
    if (!parts) { return payload; }

    // we are replacing the entire payload, cause why not
    if (parts.length === 0) { return value; }

    const updeepPath = [];
    let updeepValue;

    let parent, nextPart, nextPartType;
    let current = payload;
    let currentType = typeof(current);
    let pathSoFar = 'this';

    for (let i = 0; i < parts.length; i++) {
      nextPart = parts[i];
      nextPartType = typeof(nextPart);

      if (currentType === 'undefined') {
        currentType = 'object';
        current = nextPartType === 'number' ? [] : {};
        if (updeepValue) {
          parent[parts[i-1]] = current;
        } else {
          parent = null;
          updeepValue = current;
        }
      }

      if (current === null || (currentType !== 'object' && currentType !== 'string')) {
        throw errors.PayloadPathMismatch({
          message: `Payload is wrong type at '${pathSoFar}' to set value`
        });
      }

      if (currentType === 'string' || Array.isArray(current)) {
        if (nextPartType !== 'number') {
          throw errors.PayloadPathMismatch({
            message: `Payload is wrong type at '${pathSoFar}' to set value`
          });
        }
      }

      if (currentType === 'string' && i !== parts.length - 1) {
        throw errors.PayloadPathMismatch({
          message: 'Cannot set properties on part of a string'
        });
      }

      if (i === parts.length - 1) {
        if (currentType === 'string') {
          value = String(value);
          if (value.length !== 1) {
            throw errors.PayloadPathMismatch({
              message: 'Can only set single chars at string index'
            });
          }
          current = current.substr(0, nextPart) + value +
            current.substr(nextPart + 1);
          // currentType will never be a string if
          // updeepValue && parent are both set
          updeepValue = current;
        } else {
          if (updeepValue) {
            current[nextPart] = value;
          } else {
            updeepPath.push(nextPart);
            updeepValue = value;
          }
        }
      } else {
        parent = current;
        current = current[nextPart];
        currentType = typeof(current);
        pathSoFar = `${pathSoFar}.[${nextPart}]`;
        if (!updeepValue) {
          updeepPath.push(nextPart);
        }
      }
    }

    return u(u.updateIn(updeepPath, u.constant(updeepValue)), payload);
  },

  remove: function(path, payload) {
    const parts = pathSplit(path);
    if (parts === false) { throw errors.InvalidPayloadPath({ path }); }
    if (!parts) { return payload; }

    // we are removing the entire payload, cause why not
    // default it to an empty object
    if (parts.length === 0) { return {}; }

    const updeepPath = [];
    let updeepValue;

    let nextPart, nextPartType;
    let current = payload;
    let currentType = typeof(current);
    let pathSoFar = 'this';

    for (let i = 0; i < parts.length; i++) {
      // nothing to remove
      if (currentType === 'undefined') { return payload; }

      if (current === null || (currentType !== 'object' && currentType !== 'string')) {
        throw errors.PayloadPathMismatch({
          message: `Payload is wrong type at '${pathSoFar}' to remove value`
        });
      }

      nextPart = parts[i];
      nextPartType = typeof(nextPart);

      if (currentType === 'string' || Array.isArray(current)) {
        if (nextPartType !== 'number') {
          throw errors.PayloadPathMismatch({
            message: `Payload is wrong type at '${pathSoFar}' to remove value`
          });
        }
      }

      if (currentType === 'string' && i !== parts.length - 1) {
        throw errors.PayloadPathMismatch({
          message: 'Cannot remove properties on part of a string'
        });
      }

      if (i === parts.length - 1) {
        if (currentType === 'string') {
          updeepValue = current.substr(0, nextPart) +
            current.substr(nextPart + 1);
        } else if (Array.isArray(current)) {
          updeepValue = uRejectIndex(nextPart);
        } else {
          updeepValue = u.omit(nextPart);
        }
      } else {
        current = current[nextPart];
        currentType = typeof(current);
        pathSoFar = `${pathSoFar}.[${nextPart}]`;
        updeepPath.push(nextPart);
      }
    }

    return u(u.updateIn(updeepPath, updeepValue), payload);
  },

  isValidExpression: function(expression, { returnError } = {}) {
    try {
      payloadHelper.evaluateExpression(expression, {});
      return returnError ? null : true;
    } catch (e) {
      return returnError ? e : false;
    }
  },

  evaluateExpression: function(expression, payload, { noErrors, frameData } = {}) {
    expression = String(expression);

    let expFunc = expCache.get(expression);
    if (!expFunc) {
      expFunc = payloadHelper._buildExpressionEvaluator(expression);
      expCache.set(expression, expFunc);
    }

    const options = frameData ? { data: frameData } : { };
    let result;
    if (noErrors) {
      try { result = expFunc(payload, options); } catch { }
    } else {
      result = expFunc(payload, options);
    }

    return result;
  },

  applyIdToString: function(obj) {
    if (!obj) { return; }
    Object.values(obj).forEach((value) => {
      if (value
        && Object.hasOwnProperty.call(value, 'id')
        && value.toString === Object.prototype.toString
      ) {
        value.toString = idToString;
      }
    });
  }
};

module.exports = payloadHelper;
