// the template tester
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import Nav from '../../components/Nav'
import Styles from '../../styles/doc.module.scss'
import ContentStyle from '../../styles/content.module.scss'
import Footer from '../../components/Footer'
import TemplateTester from '../../components/template-tester'

const TemplateTesterPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Template Tester"
        description={`The Losant Template Tester is an interactive tool that provides a way for users to practice using Losant's Template Engine to access payload data and build Experiences.`}
        keywords={['Losant Template Tester', 'Losant Template Practice']}
      />
      <Nav location={location} />
      <div style={{ padding: '40px' }} className={Styles.DocWrapper}>
        <div className={ContentStyle.SectionWrapper}>
          <div
            className={ContentStyle.Section}
            style={{ maxWidth: '900px' }}
            role="main"
          >
            <div id="top-info">
              <h1>Template Tester</h1>
              <p>
                The Losant Template Tester is an interactive tool that provides
                a way for users to practice, test, and explore examples using
                Losant's Template Engine. Losant's Template Engine is the
                primary way for Losant Developers to{' '}
                <a target="_blank" href="/workflows/accessing-payload-data/">
                  access payload data
                </a>{' '}
                in the{' '}
                <a target="_blank" href="/workflows/overview/">
                  Workflow Engine
                </a>{' '}
                and access context while building{' '}
                <a target="_blank" href="/experiences/overview/">
                  End-User Experiences.
                </a>{' '}
                The Losant Template Tester is intended to be used by both new
                and experienced Losant Developers to hone their skills and
                improve the quality of their work.
              </p>
              <p>
                If you need help, please see the FAQ section below,
                or drop us a line in{' '}
                <a target="_blank" href="https://forums.losant.com">
                  our Forums
                </a>{' '}
                .
              </p>
            </div>
            <TemplateTester />
            <h2 id="template-tester-faq" style={{ marginTop: 35 }}>
              FAQ
            </h2>
            <h3>How do I validate JSON?</h3>
            <p>
              There are a few tools on the internet that will help you validate
              JSON. We recommend{' '}
              <a target="_blank" href="https://jsonlint.com/">
                JSON Lint.
              </a>
            </p>
            <h3>How do I validate my template?</h3>
            <p>
              There are many tools online, but we recommend{' '}
              <a target="_blank" href="http://tryhandlebarsjs.com/">
                Try Handlebars.
              </a>{' '}
            </p>
            <h3>Where do I get context from?</h3>
            <p>
              You can copy/paste context directly from a workflow's{' '}
              <a
                target="_blank"
                href="https://docs.losant.com/workflows/debug/debug/#debug-panel"
              >
                Debug Panel
              </a>
              , an experience's{' '}
              <a
                target="_blank"
                href="https://docs.losant.com/experiences/views/#render-logs"
              >
                Render Log
              </a>
              , or write one yourself!
            </p>

            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateTesterPage

export const pageQuery = graphql`
  query {
    allNavigationJson {
      edges {
        node {
          id
          name
          order
          links {
            name
            url
            links {
              name
              url
            }
          }
        }
      }
    }
  }
`
