export default [
  {
    id: 'empty',
    name: 'Hello World',
    description: 'Welcome to the Template Tester.',
    context: {
      data: {
        hello: 'world',
      },
    },
    template: '{{ data.hello }}',
  },
  {
    id: 'each',
    name: 'Each',
    description:
      'Iterate over arrays and objects using the {{#each}} block helper. ',
    context: {
      data: {
        anArray: [
          44,
          'Goodbye world',
          false,
          {
            anObjectPropInAnArray: "I'm deep!",
          },
        ],
      },
    },
    template: `{{#each data.anArray}}
  {{@index}}: {{this}}; 
{{/each}}`,
  },
  {
    id: 'if-conditional',
    name: 'If Conditional Block Helper',
    description: 'Display a value based on a conditional statement.',
    context: {
      data: {
        shouldDisplay: true,
      },
    },
    template:
      '{{#if data.shouldDisplay}}\nI should display!\n{{else}}\nI should NOT display!\n{{/if}}',
  },
  {
    id: 'jsonEncode',
    name: 'jsonEncode',
    description: 'Use the jsonEncode helper to encode a value to valid JSON.',
    context: {
      data: {
        anObject: {
          aNestedArray: ['Losant IoT', 20, {}],
          aNestedNumber: 56,
          aNestedString: 'Losant rocks!',
        },
      },
    },
    template: '{{jsonEncode data.anObject 2}}',
  },
  {
    id: 'format-number',
    name: 'Format Number',
    description: 'Format a number using the Format helper.',
    context: {
      data: {
        aDecimal: 32.4943823,
      },
    },
    template: "{{format data.aDecimal ',.2f'}}",
  },
  {
    id: 'output-number',
    name: 'Rendering HTML',
    description: 'Output HTML using templating.',
    context: {
      data: {
        name: 'Alan',
        hometown: 'Somewhere, TX',
        kids: [
          {
            name: 'Jimmy',
            age: '12',
          },
          {
            name: 'Sally',
            age: '4',
          },
        ],
      },
    },
    template: `<p>Hello, my name is {{data.name}}. I am from {{data.hometown}}. I have {{length data.kids}} kids.</p>
 
    <ul>
      {{#each data.kids}}
        <li>{{this.name}}</li>
      {{/each}}
    </ul>`,
  },
  {
    id: 'lookup',
    name: 'Lookup',
    description: 'Lookup a value based on a key.',
    context: {
      data: {
        incomingId: 'deviceId1',
        devices: {
          deviceId1: { name: 'Device 1' },
          deviceId2: { name: 'Device 2' },
        },
      },
    },
    template: `{{lookup (lookup data.devices data.incomingId) 'name'}}`,
  },
  {
    id: 'valueByKey',
    name: 'Value By Key',
    description:
      'Return a value of an object array if it matches the given key.',
    context: {
      data: {
        name: 'Losant',
        location: 'Cincinnati, OH',
        devices: [
          {
            id: '12398f',
            name: 'device-01',
            type: 'gateway',
          },
          {
            id: 'rqw3r3',
            name: 'device-02',
            type: 'standalone',
          },
        ],
      },
    },
    template: `{{valueByKey data.devices "rqw3r3" "id" "type"}}`,
  },
  {
    id: 'add',
    name: 'Add',
    description: 'Add two values together.',
    context: {
      data: {
        numberOne: 42,
        numberTwo: 8,
      },
    },
    template: `{{add data.numberOne data.numberTwo}}`,
  },
  {
    id: 'typeOf',
    name: 'Type Of',
    description: 'Determine the type of a value.',
    context: {
      data: {
        aNumber: 10,
        aBool: true,
        aString: 'Losant Rocks!',
        isNull: null,
        isUndef: undefined,
        isArray: [0, 1, 2, 3, 4, 5],
      },
    },
    template: `{{typeof data.aNumber}}\n\n{{typeof data.aBool}}\n\n{{typeof data.aString}}\n\n{{typeof data.isNull}}\n\n{{typeof data.isUndef}}\n\n{{typeof data.isArray}}`,
  },
  {
    id: 'gpsDistance',
    name: 'GPS Distance',
    description: 'Calculate the distance between two GPS Strings.',
    context: {
      data: {
        placeOne: '39.108405,-84.5099472',
        placeTwo: '35.7197509,-83.5108076',
      },
    },
    template: `The distance between Place One and Place Two is:\n\n{{gpsDistance data.placeOne data.placeTwo}}\n\nmeters.`,
  },
  {
    id: 'decodeBase64',
    name: 'Decode Base64',
    description: 'Decode a Base64 encoded String.',
    context: {
      data: {
        message:
          'VGhlIExvc2FudCBFbnRlcnByaXNlIElvVCBQbGF0Zm9ybSBpcyBhbiBhcHBsaWNhdGlvbiBlbmFibGVtZW50IHBsYXRmb3JtIHRoYXQgYWxsb3dzIHlvdSB0byBidWlsZCBhcHBsaWNhdGlvbnMgdGhhdCBzZWN1cmVseSBzY2FsZSB0byBtaWxsaW9ucyBvZiBkZXZpY2VzLiBXaXRoIHBvd2VyZnVsIGRhdGEgY29sbGVjdGlvbiwgYWdncmVnYXRpb24sIGFuZCB2aXN1YWxpemF0aW9uIHRvb2xzLCBMb3NhbnQgYWxsb3dzIHlvdSB0byB1bmRlcnN0YW5kIGFuZCBxdWFudGlmeSB2YXN0IGFtb3VudHMgb2YgZGF0YS4=',
      },
    },
    template: `{{decodeBase64 data.message}}`,
  },
  {
    id: 'defaultTo',
    name: 'Default To',
    description: 'Return a default value if null, undefined, or empty.',
    context: {
      data: {
        aNullProp: null,
        anUndefinedProp: undefined,
        anEmptyString: '',
        aNumberProp: 10,
      },
    },
    template: `{{defaultTo data.aNullProp "A Null Prop"}}\n\n{{defaultTo data.anUndefinedProp "Not Defined in Context"}}\n\n{{defaultTo data.anEmptyString "An Empty String"}}\n\n{{defaultTo data.aNumberProp "A Number Prop"}}`,
  },
  {
    id: 'formatGps',
    name: 'Format GPS String',
    description: 'Reformat a GPS String.',
    context: {
      data: {
        aGpsString: '37.33233141,-122.0312186',
      },
    },
    template: `{{{formatGps data.aGpsString "degrees"}}}`,
  },
]
